<template>
    <div class="mainDashboardContainer">
        <b-row v-if="client.status" class="content-header">
          <b-col class="content-header-left mb-2" cols="10">
            <b-row class="breadcrumbs-top">
              <b-col cols="12">
                <h2 class="content-header-title float-left pr-1 mb-0 text-white">
                  <b-avatar class="mr-1" size="32" :src="client.logo" :text="avatarText(client.name)"
                    :variant="client.logo ? `white` : `primary`" />
                  {{ client.name }}
                </h2>
                <div class="breadcrumb-wrapper breadcrumb-container">
                  <b-breadcrumb>
                    <b-breadcrumb-item to="/">
                      <feather-icon icon="HomeIcon" size="16" class="align-text-top text-white" />
                    </b-breadcrumb-item>
                    <b-breadcrumb-item v-for="item in breadcrumbs" :key="item.text" :active="item.active" :to="item.to"
                      class="text-white">
                      {{ item.text }} 
                    </b-breadcrumb-item>
                  </b-breadcrumb>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="2">
          </b-col>
        </b-row>
<div v-if="client.status &&
          client.integrations.salesforce &&
          client.salesforce.adAccountIDs.length > 0
          ">
          <b-overlay class="dashboard-overlay" :show="isClientLoad" rounded="sm">

            <date-selector :client="client._id" :slug="$route.params.slug"  :chartSummaryData="quarterlyData" 
            :businessType="client.businessType"  :iconCardType="iconType"
             :groupCardFlag="isqQuarterlyDataLoad" @fetchDashboard="fetchDashboardData" @fetchLeads="fetchLeadsData" @fetchCampaigns="fetchCampaignData" 
             @fetchContacts="fetchContactsData"
             @fetchReports="fetchReportsData"
             @fetchQuaterlyData="fetchQuarterlyData"
            />
           <b-card class="tabs-container salesforceAlignments"> 
                <b-tabs fill>
                  <b-tab
                  title="Dashboard"
                  @click="changeTab('Dashboard')"
                  active
                  ></b-tab>
                  <b-tab
                  title="Active Campaigns"
                  @click="changeTab('ActiveCampaigns')"
                ></b-tab>
                  <b-tab
                    title="Leads"
                    @click="changeTab('Leads')"
                  ></b-tab>
                <b-tab
                title="Contacts"
                @click="changeTab('Contacts')"
              ></b-tab>
              <b-tab
              title="Reports"
              @click="changeTab('Reports')"
            ></b-tab>
                </b-tabs>
            </b-card> 
              <div>
                <SalesforceTable :isTableDataLoad="isDashboardDataLoad" v-if="this.tabName === 'Dashboard'" title="Dashboard"  exportsType='Dashboard' type="Dashboard" :chartData="this.dashboardData" />
                <SalesforceTable :isTableDataLoad="isLeadsDataLoad" v-if="this.tabName === 'Leads'" title="Leads"  exportsType='leads' type="Leads" :chartData="this.leadsData" />
                <SalesforceTable :isTableDataLoad="isCampaignsDataLoad" v-if="this.tabName === 'ActiveCampaigns'" title="Active Campaigns"  exportsType='ActiveCampaigns' type="ActiveCampaigns" :chartData="this.campaignsData" />
                <SalesforceTable :isTableDataLoad="isContactsDataLoad" v-if="this.tabName === 'Contacts'" title="Contacts"  exportsType='Contacts' type="Contacts" :chartData="this.contactsData" />
                <SalesforceTable :isTableDataLoad="isReportDataLoading" v-if="this.tabName === 'Reports'" title="Reports"  exportsType='Reports' type="Reports" :chartData="this.reportsData" />
                </div>     
       </b-overlay>
        </div>
      </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCardTitle,
  BFormCheckbox,
  BBreadcrumb,
  BBreadcrumbItem,
  BAlert,
  BLink,
  BAvatar,
  BCard,
  BButton,
  BOverlay,
  BTabs,
  BTab,
} from "bootstrap-vue";
import axios from "axios";
import DataTable from '../GoogleAds/DataTable.vue'
import multiChannelSpinner from "@/components/client/multiChannelSpinner.vue";
import { getUserToken } from "@/auth/utils";
import DateSelector from "./DateSelector.vue";
import moment from "moment";
import SalesforceTable from './SalesforceTable.vue'
import MultiHeaderProjectChannel from "@/views/clientFeature/MultiheaderProjectChannel.vue";
export default {
    data() {
    return {
      totalLeadSquaredData:{},
      selectedStartDate:"",
      selectedEndDate:"",
      productProjectNamesChange:[],
      productProjectIntialNamesChange:[],
      productWiseTypeOptions:[],
      creativeOptions:[],
      intiallOptionsForGroup:[],
      optionsLength:0,
      zoneWiseLength:0,
      selectedZoneTypeForFilter:[],
      selectedProductType:"all",
      adRunningError:"",
      zoneWiseTypeOptions:null,
      zoneWiseTypeOptionsFlag:false,
      openScheduleModal:false,
      isProductDatafetched:false,
      isZonewiseDatafetched:false,
      productWiseData:[],
      zoneWiseData:[],
      startDateProjectName:"",
      endDateProjectName:"",
      tabName: "",
      projectsTab: false,
      isProjectDataLoad:false,
      client: {
        status: false,
        integrations: {
          googleAds: null,
        },
        googleAdsCustomerID: null,
      },
      errorMessage: "",
      iconType: "",
      breadcrumbs: [
        {
          text: "Overview",
          to: "/dashboard",
        },
      ],
      isReportDataLoading: true,
      isClientLoad: true,
      campaigns: [],
      charts: {
        data: [],
        totalData: {},
      },
      graphs: [],
      currentDateRange: "",
      chartData: [],
      projectData:[],
      selectedZoneTypeForFilter:[],
      zoneListChange:[],
      leadsData:[],
      campaignsData:[],
      contactsData:[],
      reportsData:[],
      dashboardData:[],
      quarterlyData:[],
      isqQuarterlyDataLoad:false,
      isDashboardDataLoad:false,
      isReportsDataLoad:false,
      isLeadsDataLoad:false,
      isContactsDataLoad:false,
      isCampaignsDataLoad:false,
      isTotalIsLoad:false
    };
  },
  components: {
    BRow,
    BCol,
    BCardTitle,
    BFormCheckbox,
    BBreadcrumb,
    BBreadcrumbItem,
    BAlert,
    BLink,
    BAvatar,
    BCard,
    BButton,
    BOverlay,
    BTabs,
    BTab,
    DateSelector,
    MultiHeaderProjectChannel,
    multiChannelSpinner,
    SalesforceTable,
    DataTable
  },
  methods:{
    fetchQuarterlyData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isqQuarterlyDataLoad = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!startDate && !this.endDate) {
        this.startDateProjectName = " "
        this.endDateProjectName = " "
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];
        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        this.startDateProjectName = formattedStartDate
        this.endDateProjectName = formattedEndDate
      }
      if (startDate && endDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${startDate}&endDate=${endDate}`;
          this.startDateProjectName = " "
        this.endDateProjectName = " "
          this.startDateProjectName = startDate
        this.endDateProjectName = endDate
      }
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/salesforce/quarterly-performance/?clientID=${this.client._id}&${additionalQueryParams}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.quarterlyData = response && response.data && response.data.performance
          this.isqQuarterlyDataLoad = false;    
        })
        .catch(error => {
          this.isqQuarterlyDataLoad = false;
          console.log(error);
        });
    },
    fetchDashboardData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isDashboardDataLoad = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!startDate && !this.endDate) {
        this.startDateProjectName = " "
        this.endDateProjectName = " "
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];
        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        this.startDateProjectName = formattedStartDate
        this.endDateProjectName = formattedEndDate
      }
      if (startDate && endDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${startDate}&endDate=${endDate}`;
          this.startDateProjectName = " "
        this.endDateProjectName = " "
          this.startDateProjectName = startDate
        this.endDateProjectName = endDate
      }
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/salesforce/dashboards/?clientID=${this.client._id}&${additionalQueryParams}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.dashboardData = response && response.data && response.data.dashboards
          this.isDashboardDataLoad = false;    
        })
        .catch(error => {
          this.isDashboardDataLoad = false;
          console.log(error);
        });
    },
    fetchContactsData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isContactsDataLoad = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!startDate && !this.endDate) {
        this.startDateProjectName = " "
        this.endDateProjectName = " "
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];
        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        this.startDateProjectName = formattedStartDate
        this.endDateProjectName = formattedEndDate
      }
      if (startDate && endDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${startDate}&endDate=${endDate}`;
          this.startDateProjectName = " "
        this.endDateProjectName = " "
          this.startDateProjectName = startDate
        this.endDateProjectName = endDate
      }
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/salesforce/contacts/?clientID=${this.client._id}&${additionalQueryParams}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.contactsData = response && response.data && response.data.contacts
          this.isContactsDataLoad = false;    
        })
        .catch(error => {
          this.isContactsDataLoad = false;
          console.log(error);
        });
    },
    fetchReportsData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isReportDataLoading = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!startDate && !this.endDate) {
        this.startDateProjectName = " "
        this.endDateProjectName = " "
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];
        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        this.startDateProjectName = formattedStartDate
        this.endDateProjectName = formattedEndDate
      }
      if (startDate && endDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${startDate}&endDate=${endDate}`;
          this.startDateProjectName = " "
        this.endDateProjectName = " "
          this.startDateProjectName = startDate
        this.endDateProjectName = endDate
      }
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/salesforce/reports/?clientID=${this.client._id}&${additionalQueryParams}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.reportsData = response && response.data && response.data.reports
          this.isReportDataLoading = false;    
        })
        .catch(error => {
          this.isReportDataLoading = false;
          console.log(error);
        });
    },
    fetchCampaignData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isCampaignsDataLoad = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!startDate && !this.endDate) {
        this.startDateProjectName = " "
        this.endDateProjectName = " "
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];
        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        this.startDateProjectName = formattedStartDate
        this.endDateProjectName = formattedEndDate
      }
      if (startDate && endDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${startDate}&endDate=${endDate}`;
          this.startDateProjectName = " "
        this.endDateProjectName = " "
          this.startDateProjectName = startDate
        this.endDateProjectName = endDate
      }
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/salesforce/active-campaigns/?clientID=${this.client._id}&${additionalQueryParams}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.campaignsData = response && response.data && response.data.campaigns
          this.isCampaignsDataLoad = false;    
        })
        .catch(error => {
          this.isCampaignsDataLoad = false;
          console.log(error);
        });
    },
    fetchLeadsData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isLeadsDataLoad = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!startDate && !this.endDate) {
        this.startDateProjectName = " "
        this.endDateProjectName = " "
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];
        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        this.startDateProjectName = formattedStartDate
        this.endDateProjectName = formattedEndDate
      }
      if (startDate && endDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${startDate}&endDate=${endDate}`;
          this.startDateProjectName = " "
        this.endDateProjectName = " "
          this.startDateProjectName = startDate
        this.endDateProjectName = endDate
      }
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/salesforce/leads/?clientID=${this.client._id}&${additionalQueryParams}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.isLeadsDataLoad = false;
          let processedLeads = []
          if(response && response.data){
            processedLeads = Object.entries(response.data && response.data.leads).flatMap(([parentDate, leads]) => {
            return leads.map(lead => ({
                ...lead,
                parentDate // Add the parentDate (key) dynamically to each lead object
              }));
           });
          }
          this.leadsData = processedLeads
        
        })
        .catch(error => {
          this.isLeadsDataLoad = false;
          console.log(error);
        });
    },
    changeTab(name) {
      this.tabName = name;
    },
    avatarText(value) {
      if (!value) return "";
      const nameArray = value.split(" ");
      return nameArray.map((word) => word.charAt(0).toUpperCase()).join("");
    },
    formatDateDisplay(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    formatDate(inputDate) {
    const [year, month, day] = inputDate.split('-');
    const updatedDate = `${day}-${month}-${year}`;
    return updatedDate;
   },
    getClient() {
      const slug = this.$route.params.slug;
      this.isClientLoad = true
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/client/${slug}`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.client = { ...response.data.client, status: true };
          this.graphs = response.data.client.googleAdsGraphs
            ? response.data.client.googleAdsGraphs
            : [];
          this.breadcrumbs.push({
            text: response.data.client.name,
            to:`/dashboard`,
            active: false,
          });
          this.breadcrumbs.push({
            text: "Salesforce Dashboard",
            to: `/client/${response.data.client.slug}/dashboard/salesforce`,
            active: true,
          });
            this.fetchLeadsData();
            this.fetchCampaignData();
            this.fetchContactsData();
            this.fetchReportsData();
            this.fetchQuarterlyData();
            this.fetchDashboardData();
            // this.fetchAllDataWiseData()
          
          this.isClientLoad = false
        })
        .catch((error) => {
          console.log(error.response.data);
          this.client.status = false;
          this.errorMessage = error.response.data.error;
          this.isClientLoad = false
        });
    },
  },
  filters: {
    date(val) {
      return val ? moment(val).format("DD-MM-YYYY") : "";
    }
  },
  mounted() {
    this.tabName = "Dashboard";
    this.getClient();
  },
}
</script>
<style lang="scss">
// @import "@core/scss/vue/libs/vue-select.scss";

.salesforceAlignments {
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  .card-body {
    padding: 0.5rem !important;
  }
}

.breadcrumb-container a {
  color: antiquewhite !important;
}

.dashboard-overlay .rounded-sm {
  inset: -20px !important
}
.spinnerCentered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
  /* Set the height of the container */
}
</style>