<template>
    <b-card no-body class="chart-salesforce">
      <b-card-body class="statistics-body"> 
        <div v-if="this.groupCardFlag" class="spinnerCentered">
          <multiChannelSpinner />
        </div>
        <div v-else>
          <b-row v-if="summaryData.length === 0" class="iconCentered">
            <b-col cols="12"><div class="mt-2"><h5>No Data Found.</h5></div></b-col>
         </b-row>
         <b-row v-else> 
          <template style="display: flex;justify-content: center;align-items: center;height: 100%;">
            <div>
              <apexchart
                :options="chartOptions"
                :series="chartSeries"
                type="area" height="200" width="720" 
              />
            </div>
          </template>
          
         </b-row>
        </div>
    
      </b-card-body>
    </b-card>
  </template>
  
  <script>
  import {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
  } from "bootstrap-vue";
  import commaNumber from "comma-number";
  import millify from "millify";
  import VueApexCharts from "vue-apexcharts";
  import projectsMap from "@/@core/utils/projectDetailMap";
  import { f } from "vue-phone-number-input/dist/vue-phone-number-input.common";
  import multiChannelSpinner from "@/components/client/multiChannelSpinner.vue";
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BCardHeader,
      BCardTitle,
      BCardText,
      BCardBody,
      BMedia,
      BAvatar,
      BMediaAside,
      BMediaBody,
      apexchart: VueApexCharts,
      multiChannelSpinner
    },
    props: {
      iconType:{
        type:String
      },
      businessType:{
        type:Array
      },
      groupCardFlag:{type:Boolean},
      adRunningError:{type:String},
      summaryData: {
        type: Array,
      },
    },
    computed: {
 
    },
    data() {
      return {
        chartOptions: {
            chart: {
            type: "bar",
            height: 250,
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            }
          },
          stroke: {
                curve: 'smooth'
              },
            xaxis: {
              categories: ['Q2 2024', 'Q3 2024', 'Q4 2024'], 
            },
            yaxis: [
              {
                title: {
                  text: 'Revenue',
                },
              },
              {
                opposite: true,
                title: {
                  text: 'Deals',
                },
              },
          ],
          title: {
            text: 'Revenue and Deals by Quarter',
            align: 'left',
          },
      },
      chartSeries: [  
        {
          name: 'Total Revenue',
          type: 'column',
          data: [60000, 3240000, 345000],
        },
        {
          name: 'Total Deals',
          type: 'line',
          data: [1, 13, 4],
        },
      ],
        projectsMap:projectsMap
      };
    },
    mounted() {
    this.initializeChart();
  },
    methods: {
    initializeChart() {
      const categories = this.summaryData.map(
        (item) => `Q${item.quarter} ${item.year}`
      );
      const revenueData = this.summaryData.map((item) => item.totalRevenue);
      const dealsData = this.summaryData.map((item) => item.totalDeals);

      this.chartOptions.xaxis.categories = categories;

      this.series = [
        {
          name: "Total Revenue",
          type: "column",
          data: revenueData,
        },
        {
          name: "Total Deals",
          type: "line",
          data: dealsData,
        },
      ];
    },
    updateChartData() {
      this.initializeChart(); // Reinitialize chart data with new entries
    },
    },
  };
  
  </script>
  
  <style lang="scss">
  .iconCentered {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .chart-salesforce {
    .card-header {
      padding-left: 2.4rem;
    }
    .statistics-body {
      padding: 1rem 2rem 0rem !important;
  
      .avatar .avatar-content {
        width: 48px;
        height: 48px;
  
        .avatar-icon {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  
  
  </style>