<template>
  <div>
    <b-card class="screenAlignmentDataTable">
      <div>
        <div class="schedule-hearder">
          <div>
            <b-card-title>
              <h3 class="app-title mdsm">{{ title }}</h3>
            </b-card-title>
          </div>
          <div style="display: flex; justify-content: space-between;gap: 10px;">
            <!-- <b-button
              @click="exportData"
              class="exportButton"
              size="sm"
              variant="primary"
              style=" margin-top:23px"
              ><span style="font-weight: bold;">
                Export Data
                <feather-icon
                  icon="DownloadIcon"
                  size="15"
                  style="margin-left: 5px;"/></span
            ></b-button> -->
          </div>
        </div>
      </div>
      <div v-if="this.isTableDataLoad" class="spinnerCentered">
        <multiChannelSpinner />
      </div>
      <div v-else>
        <div v-if="this.chartData.length === 0" class="spinnerCentered">
            No Data found!!
           </div>
           <div v-else>
            <vue-good-table
            v-if="this.chartData.length > 0"
           class="custom-data-table mainTable"
            :columns="tableColumns"
            :rows="chartData"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm,
            }"
            :select-options="{
              enabled: false,
              selectOnCheckboxOnly: true,
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true,
              selectAllByGroup: true,
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
          >
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Showing 1 to </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3', '5', '7', '10']"
                    class="mx-1"
                    @input="
                      (value) => props.perPageChanged({ currentPerPage: value })
                    "
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="
                      (currentPage) =>
                        props.pageChanged({ currentPage: currentPage })
                    "
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
           </div>
    
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardTitle,
  BButton,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import commaNumber from "comma-number";
import millify from "millify";
import multiChannelSpinner from "@/components/client/multiChannelSpinner.vue";
import {
  sortFuntionStringintoNumericVal,
  sortFuntionNumericVal,
} from "@/components/client/SortingFunction.js";

export default {
  data() {
    return {
      pageLength: 7,
      searchTerm: "",
      currentPage: 1,
    };
  },
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BCardTitle,
    BButton,
    multiChannelSpinner,
  },
  props: {
    chartData: {
      type: Array,
    },
    businessType: {
      type: String,
    },
    type: {
      type: String,
      default: "date",
    },
    title: {
      type: String,
    },
    exportsType: {
      type: String,
    },
    subCampaign: {
      type: Object,
    },
    isTableDataLoad: {
      type: Boolean,
    },
  },
  watch: {
    chartData(newVal, oldVal) {
      // this.chartData = newVal
      this.currentPage = 1;
    },
  },
  computed: {
    tableColumns() {
      if (this.type === "Leads") {
          let columns = [
            {
              label: "Date",
              field: "parentDate",
              tdClass: "text-center break-text",
              thClass: "text-center",
              width: "180px",
            },
            {
              label: "Name",
              field: "Name",
              sortFn: this.sortFn,
            },
            {
              label: "Email",
              field: "Email",
              sortFn: this.sortFn,
            },
            {
              label: "Company",
              field: "Company",
              sortFn: this.sortFn,
            },
            {
              label: "Status",
              field: "Status",
              sortFn: this.sortFn,
            }
          ];
          return columns
      }else if (this.type === 'ActiveCampaigns') {
        let columns = [
            {
              label: "Campaign name",
              field: "Name",
              tdClass: "text-center break-text",
              thClass: "text-center",
              width: "400px",
            },
            {
              label: "Start Date",
              field: "StartDate",
              sortFn: this.sortFn,
            },
            {
              label: "End Date",
              field: "EndDate",
              sortFn: this.sortFn,
            },
            {
              label: "Status",
              field: "Status",
              sortFn: this.sortFn,
            },
          ];
          return columns
      }else if (this.type === 'Contacts') {
        let columns = [
            {
              label: "First Name",
              field: "FirstName",
              tdClass: "text-center break-text",
              thClass: "text-center",
              width: "180px",
            },
            {
              label: "Last Name",
              field: "LastName",
              sortFn: this.sortFn,
            },
            {
              label: "Phone",
              field: "Phone",
              sortFn: this.sortFn,
            },
            {
              label: "Email",
              field: "Email",
              sortFn: this.sortFn,
            },
          ];
          return columns
      }
      else if (this.type === 'Reports') {
        let columns = [
            {
              label: "Name",
              field: "Name",
              tdClass: "text-center break-text",
              thClass: "text-center",
              width: "180px",
            },
            {
              label: "Description",
              field: "Description",
              width: "280px",
              sortFn: this.sortFn,
            },
            {
              label: "Developer Name",
              field: "DeveloperName",
              sortFn: this.sortFn,
            },
            {
              label: "Folder Name",
              field: "FolderName",
              sortFn: this.sortFn,
            },
          ];
          return columns
      }else if (this.type === 'Dashboard') {
        let columns = [
            {
              label: "Title",
              field: "Title",
              tdClass: "text-center break-text",
              thClass: "text-center",
              width: "180px",
            },
            {
              label: "Description",
              field: "Description",
              sortFn: this.sortFn,
            },
            {
              label: "FolderName",
              field: "FolderName",
              sortFn: this.sortFn,
            },
          ];
          return columns
      }
      else{
        let columns = [
            {
              label: "Date",
              field: "parentDate",
              tdClass: "text-center break-text",
              thClass: "text-center",
              width: "180px",
            },
            {
              label: "Name",
              field: "Name",
              sortFn: this.sortFn,
            },
            {
              label: "Email",
              field: "Email",
              sortFn: this.sortFn,
            },
            {
              label: "Company",
              field: "Company",
              sortFn: this.sortFn,
            },
            {
              label: "Status",
              field: "Status",
              sortFn: this.sortFn,
            }
          ];
          return columns
      }
    },
  },
  methods: {
    exportData() {
      // Determine the columns to display based on the channel type
      let displayArr = {};
      if (this.exportsType === "googleCampaign") {
        displayArr = displayArr = {
          campaignName: "Campaign Name",
          costMicros: "Cost",
          impressions: "Impressions",
          clicks: "Clicks",
          conversions: "Status",
          conversionRate: "Conversion Rate %",
          cpc: "CAC",
          ctr: "CTR",
          avg_cpm: "Avg. CPM",
          cpc: "Avg. CPC",
        };
      }
      if (this.exportsType === "metaCampaign") {
        displayArr = displayArr = {
          campaignName: "Campaign Name",
          spends: "Spends",
          impressions: "Impressions",
          clicks: "Clicks",
          ctr: "CTR",
          conversions: "Leads",
          costPerResult: "Cost per Result",
          roas: "ROAS",
          cvr: "CVR",
        };
      }
      if (this.exportsType === "amazonCampaign") {
        displayArr = displayArr = {
          key: "Product Name",
          impressions: "Impressions",
          clicks: "Clicks",
          spends: "Spends",
          sales: "Sales",
          orders: "Orders",
          ctr: "CTR",
          costPerClick: "CPC",
          conversionRate: "CVR",
          acos: "ACOS",
          roas: "ROAS",
          aov: "AOV",
        };
      }
      if (this.exportsType === "googleDaily") {
        displayArr = displayArr = {
          date: "Date",
          costMicros: "Cost",
          impressions: "Impressions",
          clicks: "Clicks",
          conversions: "Leads",
          conversionRate: "Conversion Rate %",
          cpc: "CAC",
          ctr: "CTR",
          avg_cpm: "Avg. CPM",
          cpc: "Avg. CPC",
        };
      }
      if (this.exportsType === "metaDaily") {
        displayArr = displayArr = {
          date: "Date",
          impressions: "Impressions",
          clicks: "Clicks",
          ctr: "CTR",
          spends: "Spends",
          cpc: "CPC",
          costPerResult: "CPR",
          conversions: "Leads",
          roas: "ROAS",
        };
      }
      if (this.exportsType === "amazonDaily") {
        displayArr = displayArr = {
          key: "Date",
          impressions: "Impressions",
          clicks: "Clicks",
          spends: "Spends",
          sales: "Sales",
          orders: "Orders",
          ctr: "CTR",
          costPerClick: "CPC",
          conversionRate: "CVR",
          acos: "ACOS",
          roas: "ROAS",
          aov: "AOV",
        };
      }
      if (this.exportsType === "amazonPlannedVsAchieved") {
        displayArr = displayArr = {
          key: "Date",
          plannedSales: "Planned Sales",
          sales: "Sales",
          plannedSpends: "Planned Spends",
          spends: "Spends",
          plannedRoas: "Planned ROAS",
          roas: "ROAS",
        };
      }
      if (this.exportsType === "amazonMonthWise") {
        displayArr = displayArr = {
          month: "Month",
          impressions: "Impressions",
          clicks: "Clicks",
          ctr: "CTR",
          spends: "Spends",
          costPerClick: "CPC",
          sales: "Sales",
          orders: "Orders",
          conversionRate: "CR",
          acos: "ACOS",
          roas: "ROAS",
          aov: "AOV",
        };
      }
      // Helper function to escape and quote each value for CSV
      const escapeCSV = (str) => {
        if (str === null || str === undefined) {
          return '""';
        }
        return `"${String(str).replace(/"/g, '""')}"`;
      };

      // Generate CSV header based on the displayArr
      const header = Object.keys(displayArr)
        .map((field) => escapeCSV(displayArr[field]))
        .join(",");

      // Generate CSV rows based on the displayArr
      const rows = this.chartData.map((item) =>
        Object.keys(displayArr)
          .map((key) => escapeCSV(item[key]))
          .join(",")
      );

      // Combine header and rows, separated by newline
      const csv = [header].concat(rows).join("\n");

      // Create a Blob for the CSV
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      // Create and trigger a temporary download link
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `${this.type}.csv`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },

    sortFn(a, b) {
      if (typeof a === "string" && typeof b === "string") {
        return sortFuntionStringintoNumericVal(a, b);
      } else {
        return sortFuntionNumericVal(a, b);
      }
    },
  },
  mounted() {
    this.currentPage = 1;
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.campaign-name-field {
  max-width: 200px;
}

.break-text {
  font-size: 12px;
  max-width: 150px;
  white-space: normal;
  overflow: visible;
}

.mainTable {
  padding: 6px !important;
}
.screenAlignmentDataTable {
  .card-body {
    padding: 0.6rem !important;
  }
}
.schedule-hearder {
  display: flex;
  padding: 0 10px;
  justify-content: space-between;
}
input {
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  width: 210px !important;
  height: 38px;
  background-color: white;
  border: 1px solid rgb(218, 211, 211);
  color: rgb(163, 153, 153);
}
.exportButton {
  height: 38px !important;
}
.spinnerCentered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
  /* Set the height of the container */
}
</style>
