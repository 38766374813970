var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"screenAlignmentDataTable"},[_c('div',[_c('div',{staticClass:"schedule-hearder"},[_c('div',[_c('b-card-title',[_c('h3',{staticClass:"app-title mdsm"},[_vm._v(_vm._s(_vm.title))])])],1),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","gap":"10px"}})])]),(this.isTableDataLoad)?_c('div',{staticClass:"spinnerCentered"},[_c('multiChannelSpinner')],1):_c('div',[(this.chartData.length === 0)?_c('div',{staticClass:"spinnerCentered"},[_vm._v(" No Data found!! ")]):_c('div',[(this.chartData.length > 0)?_c('vue-good-table',{staticClass:"custom-data-table mainTable",attrs:{"columns":_vm.tableColumns,"rows":_vm.chartData,"search-options":{
            enabled: true,
            externalQuery: _vm.searchTerm,
          },"select-options":{
            enabled: false,
            selectOnCheckboxOnly: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true,
            selectAllByGroup: true,
          },"pagination-options":{
            enabled: true,
            perPage: _vm.pageLength,
          }},scopedSlots:_vm._u([{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Showing 1 to ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['3', '5', '7', '10']},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" of "+_vm._s(props.total)+" entries ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (currentPage) { return props.pageChanged({ currentPage: currentPage }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])]}}],null,false,2038204373)}):_vm._e()],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }